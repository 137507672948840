<template>
  <div class="resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <h1 class="h5 fw-bold mb-3">{{ $t("__reportResident") }}</h1>
      <div class="card">
        <div class="card-body">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import i18n from '@/lang/lang.js'
import { resident } from '@/http/api/resident.js'
import { mapState } from 'vuex'

export default {
  name: 'SearchReportResident',
  components: { HeaderMenu },
  data () {
    return {
      resident: null,
      from: {
        resident_id: null,
        report_type: 'daily',
        date: new Date().format('yyyy-MM-dd'),
        data_type: ['restless', 'RR', 'bedStatus', 'heartRate', 'spo2', 'temp', 'foraPressure', 'foraOxygen', 'foraTemperature'],
        notify_type: ['LYB', 'LVB', 'leaveTheBedTimeOut', 'RR', 'restless', 'heartRate', 'spo2', 'temp'] // 'SITTimeOut'
      },
      data_type: ['restless', 'RR', 'bedStatus', 'heartRate', 'spo2', 'temp', 'foraPressure', 'foraOxygen', 'foraTemperature'],
      notify_type_class: {
        restless: ['restless'],
        RR: ['RR'],
        bedStatus: ['LYB', 'LVB', 'leaveTheBedTimeOut'],
        heartRate: ['heartRate'],
        spo2: ['spo2'],
        temp: ['temp']
      },
      dataTypeAll: true,
      notifyTypeAll: false,
      is_invalid: {
        date: false,
        data_type: false
      },
      feedback_message: {
        date: i18n.t('__dateIsRequired'),
        data_type: i18n.t('__atLeastOneCheckboxMustBeSelected')
      }
    }
  },
  computed: {
    ...mapState(['token', 'timezone']),
    notify_type_list () {
      var list = []
      if (this.from.report_type === 'daily') {
        this.from.data_type.forEach((dataType) => {
          if (!dataType.includes('fora')) {
            this.notify_type_class[dataType].forEach((notifyType) => {
              list.push(notifyType)
            })
          }
        })
      } else {
        if (!this.from.data_type) return list
        list.push(...this.notify_type_class[this.from.data_type])
      }
      return list
    },
    dailyMaxDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let maxDate = ''
      maxDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
      return maxDate
    },
    dailyMinDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let minDate = ''
      minDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date().addDays(-29), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-30), vm.timezone, 'YYYY-MM-DD')
      return minDate
    },
    weeklyMaxDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let maxDate = ''
      maxDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date().addDays(-6), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-7), vm.timezone, 'YYYY-MM-DD')
      return maxDate
    },
    weeklyMinDate () {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      let minDate = ''
      minDate = hours >= 12
        ? vm.$getTimeZoneDate(new Date().addDays(-29), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-30), vm.timezone, 'YYYY-MM-DD')
      return minDate
    }
  },
  watch: {
    'token.headers.Authorization' (data) {
      if (data) {
        const vm = this
        vm.getResident()
        const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
        vm.from.date = hours >= 12
          ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
          : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
      }
    },
    'from.report_type': function (data) {
      const vm = this
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      if (data === 'daily') {
        vm.from.data_type = []
        vm.from.date = hours >= 12
          ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
          : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
      } else {
        vm.from.data_type = null
        vm.from.date = hours >= 12
          ? vm.$getTimeZoneDate(new Date().addDays(-6), vm.timezone, 'YYYY-MM-DD')
          : vm.$getTimeZoneDate(new Date().addDays(-7), vm.timezone, 'YYYY-MM-DD')
      }
      vm.is_invalid.date = false
      vm.is_invalid.data_type = false
    },
    'from.data_type': function (data) {
      if (!data) return
      this.is_invalid.data_type = false
      if (data.length === this.data_type.length) {
        this.dataTypeAll = true
      } else {
        this.dataTypeAll = false
      }
      this.from.notify_type = this.notify_type_list
    },
    'from.date': function () {
      this.is_invalid.date = false
    }
  },
  methods: {
    getResident () {
      const vm = this
      resident.get('', vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.resident = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    clickDataTypeAll () {
      if (!this.dataTypeAll) {
        this.from.data_type = this.data_type
      } else {
        this.from.data_type = []
      }
    },
    exportReport () {
      const vm = this
      if (!vm.from.date) {
        vm.is_invalid.date = true
        return
      }
      if (vm.from.data_type && !vm.from.data_type.length) {
        vm.is_invalid.data_type = true
        return
      }
      vm.$router.push({ name: 'Graphical', query: vm.from })
    }
  },
  created () {},
  mounted () {
    const vm = this
    if (vm.token.headers.Authorization) {
      vm.getResident()
      const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours()
      vm.from.date = hours >= 12
        ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
        : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD')
    }
  }
}
</script>

<style  lang="scss">
.resident {
  .card {
    position: relative;
    &-body {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% + 20px) calc(100% + 20px);
      background-size: auto;
      opacity: 0.1;
      z-index: 0;
    }
  }
}
</style>
