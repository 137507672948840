import { axiosConfig } from './axios-config.js'

export const notifyTemplate = {
  // 取得通知模板
  get: (id, token) => axiosConfig.get(`/api/notify/${id || ''}`, token),

  // 新增通知模板
  create: (data, token) => axiosConfig.post('/api/notify', data, token),

  // 更新通知模板
  update: (id, data, token) => axiosConfig.patch(`/api/notify/${id}`, data, token),

  // 刪除通知模板
  delete: (id, token) => axiosConfig.delete(`/api/notify/${id}`, token)
}
