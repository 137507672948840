import { axiosConfig } from './axios-config.js'
export const resident = {
  // 取得居民清單
  get: (id, token) => axiosConfig.get(`/api/resident/${id || ''}`, token),

  // 新增居民
  create: (data, token) => axiosConfig.post('/api/resident', data, token),

  // 更新居民資訊
  update: (id, data, token) => axiosConfig.patch(`/api/resident/${id}`, data, token),

  // 刪除居民
  delete: (id, token) => axiosConfig.delete(`/api/resident/${id}`, token)
}
